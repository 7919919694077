@font-face {
    font-family: "Grotesk";
    src: url("./fonts/basis-grotesque-bold-pro.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Grotesk";
    src: url("./fonts/basis-grotesque-medium-pro.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Grotesk";
    src: url("./fonts/basis-grotesque-regular-pro.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Rois";
    src: url("./fonts/Rois-Medium.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  