@import "@anpdev/anp-bootstrap/src/main.scss";
/* The popup form - hidden by default */
.form-popup {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 60%;
	border: 3px solid #f1f1f1;
	z-index: 9;
	transform: translate(-50%, -50%);
}

/* Add styles to the form container */
.form-container {
	max-width: 100%;
	padding: 10px;
	background-color: white;
}

/* Full-width input fields */
.form-container input[type="text"] {
	width: 100%;
	padding: 15px;
	border: none;
	background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type="text"]:focus,
.form-container input[type="password"]:focus {
	background-color: #ddd;
	outline: none;
}

.checkbox {
	font-size: 0.8rem;
	color: $dark;
}

.form-check-input{

	&:checked {
		background-color: $dark !important;
	}
}

#myForm{
	display: none;
}

@media (min-width:1920px){
	.form-popup{
		width: 35%;
	}
}
html {
	height: 100vh;
}

body {
	min-height: 100vh;
	display: flex;
    flex-direction: column;
}