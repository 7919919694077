// $primary: #000e2d;
// $secondary: #f2939c;
// $success: green;
// $info: #ece94a;
// $warning: orange;
// $danger: #ff0021;
// $light: #b6dceb;
// $faded: #e8ebf7;
// $dark: #0039d8;
// $white: #ffffff;

// navbar styling
// from bootstrap
.nav-link {
    display: block;
    padding: var(--#{$prefix}nav-link-padding-y) var(--#{$prefix}nav-link-padding-x);
    @include font-size(var(--#{$prefix}nav-link-font-size));
    font-weight: var(--#{$prefix}nav-link-font-weight);
    color: $dark !important;
    text-decoration: if($link-decoration ==none, null, none);
    @include transition($nav-link-transition);

    &:hover,
    &:focus {
        color: rgba(0, 0, 0, .7) !important;
        text-decoration: if($link-hover-decoration ==underline, none, null);
    }

    // Disabled state lightens text
    &.disabled {
        color: var(--#{$prefix}nav-link-disabled-color);
        pointer-events: none;
        cursor: default;
    }
}

// ANP's
.navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, .15);
    z-index: 1;
}

.navbar-brand {
    font-family: 'Grotesk';
    font-weight: 300;
    font-size: 18px;
    font-variant-ligatures: none;
    -webkit-font-smoothing: antialiased;
    transform: translateY(-4px);
}

.navbar-brand:hover {
    color: rgba(0, 0, 0, .7) !important;
}

.navbar-nav {
    .nav-link.active {
        color: rgba(0, 0, 0, .7) !important;
    }
}

.nav-item {
    white-space: nowrap;
}

// .navbar-toggler-icon {
//     color: gray;
//     font-size: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .navbar-toggler {
//     z-index: 2;
// }

// custom ANP search bar
.anp-search {

    position: relative;
    width: 100%;

    ::placeholder {
        color: #0e1832;
    }

    input[type=search] {
        width: 100%;
        color: $dark;
        background-color: #CED8F6;
        // border: 1px solid #0e1832;
        border: none;
        height: 1.8em;
        font-size: 1em;
        font-weight: 500;
        border-radius: 1.5em;
        padding: 1.5em 1.4em;
    }

    button[type=submit] {
        position: absolute;
        right: -1px;
        background-color: $dark;
        border: none;
        height: calc(3em + 2px);
        width: calc(3em + 2px);
        border-radius: 1.9em;
        color: #FFF;
    }
}

//pagination 
.pagination {
    // scss-docs-start pagination-css-vars
    --#{$prefix}pagination-padding-x: #{$pagination-padding-x};
    --#{$prefix}pagination-padding-y: #{$pagination-padding-y};
    // @include rfs($pagination-font-size, --#{$prefix}pagination-font-size);
    --#{$prefix}pagination-color: #{$pagination-color};
    --#{$prefix}pagination-bg: transparent !important;
    --#{$prefix}pagination-border-width: 1px !important;
    --#{$prefix}pagination-border-color: $dark !important;
    --#{$prefix}pagination-border-radius: #{$pagination-border-radius};
    --#{$prefix}pagination-hover-color: #{$pagination-hover-color};
    --#{$prefix}pagination-hover-bg: transparent;
    --#{$prefix}pagination-hover-border-color: rgba(0, 0, 0, .7);
    --#{$prefix}pagination-focus-color: #{$pagination-focus-color};
    --#{$prefix}pagination-focus-bg: #{$pagination-focus-bg};
    --#{$prefix}pagination-focus-box-shadow: #{$pagination-focus-box-shadow};
    --#{$prefix}pagination-active-color: white;
    --#{$prefix}pagination-active-bg: $dark;
    --#{$prefix}pagination-active-border-color: $dark !important;
    --#{$prefix}pagination-disabled-color: #{$pagination-disabled-color};
    --#{$prefix}pagination-disabled-bg: transparent !important;
    --#{$prefix}pagination-disabled-border-color: #{$pagination-disabled-border-color};
    // scss-docs-end pagination-css-vars

    display: flex;
    @include list-unstyled();
}

// to adjust disabled prev and next btn bg color and border color
.page-link {
    padding: 10px 20px;
    border: solid 1px $dark !important;

    &.active,
    .active>& {
        color: $white !important;
        background-color: $dark !important;
    }

    &.disabled,
    .disabled>& {
        color: var(--#{$prefix}pagination-disabled-color);
        pointer-events: none;
        background-color: transparent;
        border-color: $dark;
    }
}

footer {
    /* Set default font color to light color */
    color: $light;
    font-size: 1.125rem;
    /* 18px */

    h5 {
        font-size: 1.375rem;
        /* 22px */
        margin-bottom: 16px;
    }

    .cookies {
        position: fixed;
        bottom: 0px;
        padding: 20px 30px;
        background: #ece94a;
        color: #000;
        margin: 0 auto;
        overflow: hidden;
        z-index: 9999;
    }

    .cookie-text {
        max-width: 720px;
    }

    .cookies-hide {
        display: none;
    }

    .cookies-show {
        display: contents;
    }

    #socials-bar {
        color: white;
    }

}

//item.html
.related_items {
    padding: 0em 1em 0em 1em;
}

.related_items li a {
    text-decoration: none;
}

.related_items li a:hover {
    text-decoration: underline;
}

.card {
    //to be removed when ANP bootastrap is updated
    width: 100%;

    //to be removed when ANP bootastrap is updated
    .card-body {
        padding: 1rem;

        // remove margin bottom when card title is the only element
        .card-title:only-child {
            margin-bottom: 0;

            >h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: 0;
            }
        }
    }

    &.card-hover {
        transform: translateY(0rem);
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        cursor: pointer;

        //custom shadow box for ANP PP, don't remove
        &:hover {
            box-shadow: 0 0.25em 1em 0 rgba(0, 57, 216, 0.5);
            transition: transform .35s ease, box-shadow .35s ease;
        }
    }

    .card-img-top {
        &.card-img-top-contain {
            // Images in cards are always positioned with cover. But some images, like company logo's or profile pictures,
            // must fit within the card img box
            object-fit: contain;
        }
    }
}

// bootstrap header classes with adjusted font

.h3,
.h4,
.h5,
.h6 {
    font-family: $font-family-sans-serif;
}

.img-custom {
    width: 100%;
	height: 200px;
    object-fit: contain;
}

.custom_width-max-content {
    max-width: max-content;
}

.item-body {
    img {
        // some uploaded images in the content of an article are way too big and go beyond the border of a column so this will kepp them in check
        max-width: 100% !important;

        // if line below squeezes the image,, this will make sure that there are no extra white spaces above and below image
        height: initial;
    }
}